<template>
  <div>
    <van-nav-bar
        title="维修详情"
        left-arrow
        :fixed="true"
        :z-index="999"
        @click-left="back"
        />

        <div style="padding-top:46px;">
            <van-cell-group>
                <van-cell title="厂名" value="东莞维修厂" />
                <van-cell title="日期" value="2019-10-15 18:16:20" />
                <van-cell title="姓名" :value="info.ownerName" />
                <van-cell title="手机号" :value="info.phone" />
            </van-cell-group>
            <van-cell-group class="m-t-10 p-10  p-l-5">
                <van-cell title="项目清单"
                    style="border-left: 4px solid #2A9F58;margin-left: 5px; height: 30px; line-height: 30px;padding: 0; padding-left: 5px;">
                    <em><span>合计：</span><span style="color:red;padding-right:10px;">￥{{info.receivableItemAmount}}</span></em>
                </van-cell>
            </van-cell-group>
            <van-cell-group>
                <van-cell v-for="item in orderItemList" :key="item.id" :title="item.itemName" :value="'￥'+item.standPrice" />
            </van-cell-group>
            <van-cell-group class="m-t-10 p-10  p-l-5">
                <van-cell title="配件清单"
                    style="border-left: 4px solid #2A9F58;margin-left: 5px; height: 30px; line-height: 30px;padding: 0; padding-left: 5px;">
                    <em><span>合计：</span><span style="color:red;padding-right:10px;">￥{{info.receivablePartAmount}}</span></em>
                </van-cell>
            </van-cell-group>
            <van-cell-group>
                <van-cell v-for="item in orderPartList" :key=item.id>
                    <van-row type="flex" justify="space-between">
                        <van-col span="6">{{item.partName}}</van-col>
                        <van-col span="6" style="text-align:center;">{{item.price}}x{{item.counts}}</van-col>
                        <van-col span="6" style="text-align:right;">￥{{item.price * item.counts}}</van-col>
                    </van-row>
                </van-cell>
                <van-cell>
                    <template slot="title">
                        <span style="font-weight:bold;">合计金额</span>
                    </template>
                    <span style="color:red;">￥{{info.amountReceivable}}</span>
                </van-cell>
            </van-cell-group>
        </div>
  </div>
</template>

<script>
import orderModel from '../../api/order'

export default {
  data() {
    return {
        info: {},
        orderItemList: [],
        orderPartList: []
    }
  },
  methods: {
    onLoad() {
        const loading = this.$toast.loading({
            mask: true,
            duration: 0,
            message: '加载中...'
        });
        orderModel.getDetailByOId({oid: this.$route.params.id}).then(e => {
            loading.clear()
            this.info = e.data
            this.orderItemList = e.data.orderItemList
            if(this.orderItemList){
                this.orderItemList.forEach(item => {
                    item.orderPartList.forEach(items => {
                        this.zonghe += parseInt(items.inPrice * items.counts);
                        items.itemName = item.itemName
                        this.orderPartList.push(items);
                    })
                })
            }
        }).catch(() => loading.clear())
    },
    back() {
        this.$router.go(-1)
    }
  },
  created() {
    this.onLoad()
  }
};
</script>

<style lang="less" scoped>

</style>
